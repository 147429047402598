<template>
  <div class="commonBoxCourse" data-testid="box-module">
    <template v-if="getIsLoading">
      <div>
        <div class="skeleton skeleton-rating-video"></div>
        <div class="skeleton-row skeleton-text-control">
          <div class="skeleton skeleton-title-video"></div>
          <div>
            <slot name="controls"></slot>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="commonBoxCourse__titleModule">
        <div class="commonBoxCourse__header">
          <div class="commonBoxCourse__bar" :class="{ mobile: getIsMobile }">
            <Progress :value="data?.percentage_complete"/>
            <Students :value="data?.students_count" />
            <Rating :value="data?.rating"/>
          </div>
          <div class="commonBoxCourse__title">
            <TitleCourse :value="data?.title"  />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* Vuex */
import { mapGetters } from 'vuex';

/* Components */
import Rating from "@/components/common/cards/rating/Rating.vue";
import Progress from "@/components/common/cards/progress/Progress.vue";
import Students from "@/components/common/cards/students/Students.vue";
import TitleCourse from "@/components/common/title/Course.vue";

export default {
  name: "BoxModule",
  components: {
    Rating,
    Progress,
    Students,
    TitleCourse
  },
  computed: {
    ...mapGetters({
      getIsAdmin: "config/getIsAdmin",
      getShowRating: "config/getShowRating",
      getIsMobile: "layout/getIsMobile",
      getSettings: "config/getSettings",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getIsLoading: "config/getIsLoading",
    }),
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.commonBoxCourse {
  & {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 20px;
    width: 100%;
  }

  &__titleModule {
    display: flex;
    gap: 8px;
  }

  &__lock {
    display: flex;
    align-items: flex-end;
    padding-bottom: 2px;
    &--icon {
      margin-bottom: 0px;
    }
  }

  &__bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 10px;
    @extend .body-semibold-12;

    &.mobile {
      align-items: flex-start;
      gap: 5px;
      flex-direction: column-reverse;
    }
    &--space {
      display: flex;
      width: 20px;
    }
  }

  &__title {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;

    &--text {
      color: var(--opposite-color);
      margin-bottom: 0px;
      @extend .body-semibold-18;
      &__lock {
        color: var(--neutral-gray-600);
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__editCourse {
    display: flex;
    align-items: center;
    > ::v-deep .custom-button {
      color: var(--neutral-gray-400);
      border: 1px solid var(--neutral-gray-400);
      transition: opacity 0.3s ease;
      &:hover {
        transition: opacity 0.3s ease;
        color: var(--neutral-gray-400);
        background-color: transparent;
        opacity: 0.7;
      }
      > .svgCss {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
