<template>
    <div v-if="emptyStateCategory == 1" class="manage-category">
      <div class="manage-category__actions" @click="openCategoryModal">
        <div class="manage-category__icon">
            <IconSax name="edit" size="1rem" />
        </div>
        
        <div class="manage-category__action">
            <span>{{ $t('empty_state.manage_category') }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        getEmptyState: "home/getEmptyState"
      })
    },
    data() {
        return {
            emptyStateCategory: false
        };
    },
    mounted(){
      if(this.getEmptyState){
        this.emptyStateCategory = this.getEmptyState.empty_state_category;
      }
    },
    props: {
      course_id: {
        type: Number,
        require: true,
      },
    },
    methods: {
        openCategoryModal() {
            this.$root.$emit("bv::show::modal", "modal-categoria", "#btnShow");
        }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .manage-category {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__icon{
        display: flex;
    }

    &__action{
        margin-left: 8px;
        cursor: pointer;
    }

    &__action span{
        color: #878787;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 9.6px;
    }

    &__actions {
        display: flex;
        ::v-deep svg {
            width: 16px;
            height: 16px;
        }
        ::v-deep .svg-inner path{
          fill: #9E9E9E;

          [data-theme="dark"] & {
              fill: #878787;
          }
        }
    }
  }
  </style>
  