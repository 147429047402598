<template>
  <div class="commomTitleCourse">
    <div class="commomTitleCourse__title">
      {{ value }}
    </div>
    <div class="commomTitleCourse__controls">
      <slot name="controls"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.commomTitleCourse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  &__title {
    color: var(--opposite-color);
    @extend .body-semibold-20;
  }
}
</style>
